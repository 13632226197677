import React from "react";

import Loading from "../components/Loading";
import ReportHeader from "../components/ReportHeader";
import useInsights from "../hooks/useInsights";
import InsightsProBanner from "./InsightsProBanner";

const ResponsiveCompanies = () => {
  const data = useInsights("companies_response_time", {
    year: "2023",
  });

  const replyRange = (company) => {
    return (
      <span>
        Replies between {company.min_response_in_days} and {company.max_response_in_days}{" "}
        days
      </span>
    );
  };

  const replySingle = (company) => {
    const daysOfResponse = company.min_response_in_days || 1;
    return (
      <span>
        Replies in {daysOfResponse}
        {daysOfResponse === 1 ? " day" : " days"}
      </span>
    );
  };

  const renderCompany = (company) => {
    const minResponse = company.min_response_in_days || 1;
    const maxResponse = company.max_response_in_days || 1;

    return (
      <div key={company.company} className="mt4">
        <a
          className="size1 w700 mb-3"
          target="_blank"
          rel="noreferrer"
          href={`https://www.getonbrd.com/companies/${company.slug}`}
        >
          <img
            className="bg-white border-radius2x border border-white"
            alt=""
            src={`https://getonbrd-prod.s3.amazonaws.com/uploads/users/logo/${company.id}/${company.logo}`}
            width={48}
            height={48}
          />

          <span className="ml-2">{company.company}</span>
          <span className="ml-2 white border-radius bg-sherpa-blue-2 p-3 size0"
                title={`Median of response: ${company.median_response_in_days} days`}>
            {minResponse === maxResponse
              ? replySingle(company)
              : replyRange(company)}
          </span>
        </a>
      </div>
    );
  };

  return (
    <div>
      <ReportHeader
        epigraph="Most responsive companies"
        headline="What companies are replying the fastest to candidates?"
        icon="r-chat"
      >
        <p className="size1">
          Speed of response from companies is one of the most important factors
          that define the experience of candidates. Get on Board measures how
          long employers take to reply to candidates' applications and messages
          (you can see how we measure this <a className="w700" target="_blank" rel="noreferrer"
            href={`https://www.getonbrd.com/help/how-are-a-company-s-response-times-calculated`}
          >
            here
          </a>).
        </p>
        <p className="size1 mt3">
          These are the best-performing recruiters:
        </p>
      </ReportHeader>
      {data ? data.map((company) => renderCompany(company)) : <Loading />}
      
      <InsightsProBanner
        title="Ten una visión de rayos X sobre tu competencia con Insights Pro"
        copy="Inteligencia salarial con datos por país, industria y tamaño de empresas."
        cta="Conoce más de Insights Pro"
        tag="responsive-companies"
      />
    </div>
  );
};

export default ResponsiveCompanies;
