export default function ReportHeader({ children, headline, epigraph, icon }) {
  return (
    <div className="mb5">
      <img src={`/${icon}.svg`} width="76" className="left mr2 mt-2" />
      <h3 className="h3 bold">{epigraph}</h3>
      <h1 className="h1 w900 mb1">{headline}</h1>
      {children}
    </div>
  );
}
