import React from "react";

import Loading from "../components/Loading";
import ReportHeader from "../components/ReportHeader";
import useInsights from "../hooks/useInsights";
import InsightsProBanner from "../components/InsightsProBanner";

const ActiveCompanies = () => {
  const data = useInsights("companies_more_active_during_year", {
    year: "2023",
  });

  const renderCompany = (company) => {
    return (
      <div key={company.company} className="mt4">
        <a
          className="size1 w700 mb-3"
          target="_blank"
          rel="noreferrer"
          href={`https://www.getonbrd.com/companies/${company.slug}`}
        >
          <img
            className="bg-white border-radius2x border border-white"
            alt=""
            src={`https://getonbrd-prod.s3.amazonaws.com/uploads/users/logo/${company.id}/${company.logo}`}
            width={48}
            height={48}
          />

          <span className="ml-2">{company.company}</span>
          <span className="ml-2 white border-radius bg-green p-3 size0">
            {company.processes}
          </span>
        </a>
        {company.tags.map(([tagSlug, percentage]) => {
          const splitted = tagSlug.split("::");
          return (
            <span
              key={tagSlug}
              className="pl2"
              style={{ fontSize: percentage * 1.5 }}
            >
              <a
                rel="noreferrer"
                target="_blank"
                className="white"
                href={`https://www.getonbrd.com/jobs/tag/${splitted[1]}`}
              >
                <span className="w700 ml-2">{splitted[1]}</span>
              </a>
            </span>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <ReportHeader
        epigraph="Most active companies during 2024"
        headline="What were the most active companies of 2024 looking for?"
        icon="metrics"
      >
        <p className="size1">
          These are the companies hiring most actively during 2024 in Get on
          Board, and the top skills they are requiring. Please note that this
          includes both engineering and non-engineering positions.
        </p>
      </ReportHeader>
      {data ? data.map((company) => renderCompany(company)) : <Loading />}

      <InsightsProBanner
        title="Ten una visión de rayos X sobre tu competencia con Insights Pro"
        copy="Inteligencia salarial con datos por país, industria y tamaño de empresas."
        cta="Conoce más de Insights Pro"
        tag="active-companies"
      />

    </div>
  );
};

export default ActiveCompanies;
