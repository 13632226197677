import { useState, useEffect, useMemo } from "react";
import { apiUrl } from "../utils";

const useInsights = (insight, params = {}) => {
  const [data, setData] = useState();

  const queryParams = useMemo(
    () =>
      Object.keys(params).reduce((acc, key) => {
        const sep = acc === "" ? "?" : "&";
        return `${acc}${sep}${key}=${encodeURIComponent(params[key])}`;
      }, ""),
    [params]
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${apiUrl}/${insight}${queryParams}`);
      const data = await response.json();
      setData(data);
    };
    fetchData();
  }, [insight, queryParams]);

  return data;
};

export default useInsights;
