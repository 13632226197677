import Logo from "./Logo";
import DropdownNav from "./DropdownNav";
import Menu from "./Menu";

export default function Header() {
  return (
    <header className="gb-header">
      <div className="gb-header__container flex-v-centered">
        <Logo />
        <Menu
          className="flex justify-center py2 hide-on-mobile-and-tablet"
          navClassName="py-3 px-1"
          activeClassName="bold white bg-blue border-radius"
        />
        <DropdownNav />
      </div>
    </header>
  );
}
