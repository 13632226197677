export default function Logo() {
  return (
    <div className="gb-header__brand">
      <a className="gb-logo" href="/">
        <svg width="126px" height="20px" viewBox="0 0 1758 282" version="1.1">
          <path
            d="M107.444,102.277 C133.984,72.803 182.22,91.941 182.22,131.897 L182.22,237.937 C182.22,262.137 162.954,281.768 139.173,281.768 L43.689,281.768 C6.217,281.768 -13.344,236.418 11.959,208.317 L107.444,102.277 Z M144.155,131.897 C144.155,127.25 138.565,125.062 135.504,128.461 L40.02,234.501 C37.077,237.77 39.367,243.018 43.689,243.018 L139.173,243.018 C141.918,243.018 144.155,240.75 144.155,237.937 L144.155,131.897 Z"
            fill="#00C4F5"
          ></path>
          <path
            d="M91.407,66.982 C62.411,66.982 38.66,91.132 38.66,121.232 C38.66,151.331 62.411,175.482 91.407,175.482 C120.404,175.482 144.155,151.331 144.155,121.232 C144.155,91.132 120.404,66.982 91.407,66.982 Z M0.595,121.232 C0.595,70.008 41.118,28.232 91.407,28.232 C141.697,28.232 182.22,70.008 182.22,121.232 C182.22,172.456 141.697,214.232 91.407,214.232 C41.118,214.232 0.595,172.456 0.595,121.232 Z"
            fill="#FF7777"
          ></path>
          <path
            d="M55.015,160.501 L29.289,189.072 C38.712,198.13 50.028,205.154 62.576,209.451 L93.192,175.451 C92.599,175.472 92.004,175.482 91.407,175.482 C77.337,175.482 64.501,169.795 55.015,160.501 Z M143.556,129.442 C143.95,126.766 144.155,124.025 144.155,121.232 C144.155,108.833 140.124,97.443 133.357,88.335 C156,85.344 179.608,101.326 182.018,127.472 C180.165,156.067 165.671,181.195 144.155,196.949 L144.155,131.897 C144.155,130.973 143.934,130.147 143.556,129.442 Z"
            fill="#C3F9FF"
          ></path>
          <path
            d="M423.205,202.267 C423.205,228.293 416.696,248.002 403.679,261.392 C390.847,274.971 371.227,281.76 344.82,281.76 C332.732,281.76 320.83,280.346 309.114,277.517 C297.398,274.876 286.612,269.596 276.756,261.675 L299.909,226.596 C306.418,232.065 313.205,236.403 320.272,239.609 C327.525,242.815 335.429,244.418 343.983,244.418 C356.815,244.418 366.206,241.212 372.157,234.8 C378.294,228.576 381.363,220.561 381.363,210.754 L381.363,197.458 L380.805,197.458 C375.969,204.059 369.926,208.774 362.673,211.603 C355.606,214.243 348.911,215.563 342.588,215.563 C332.546,215.563 323.434,213.866 315.251,210.471 C307.069,206.888 300.002,201.985 294.051,195.761 C288.286,189.349 283.823,181.805 280.661,173.13 C277.5,164.454 275.919,154.93 275.919,144.558 C275.919,135.505 277.314,126.641 280.103,117.966 C283.079,109.29 287.17,101.652 292.377,95.052 C297.77,88.451 304.279,83.17 311.904,79.21 C319.528,75.061 328.176,72.986 337.846,72.986 C343.797,72.986 349.19,73.646 354.025,74.966 C359.046,76.286 363.51,77.984 367.415,80.058 C371.32,82.133 374.668,84.49 377.457,87.131 C380.247,89.582 382.478,92.034 384.152,94.486 L384.71,94.486 L384.71,76.381 L423.205,76.381 L423.205,202.267 Z M317.762,144.275 C317.762,148.801 318.598,153.139 320.272,157.288 C321.946,161.248 324.27,164.831 327.246,168.038 C330.407,171.055 333.941,173.507 337.846,175.393 C341.937,177.279 346.401,178.222 351.236,178.222 C355.885,178.222 360.255,177.279 364.346,175.393 C368.438,173.507 371.971,171.055 374.947,168.038 C378.108,164.831 380.526,161.248 382.199,157.288 C383.873,153.139 384.71,148.801 384.71,144.275 C384.71,139.748 383.873,135.505 382.199,131.545 C380.526,127.395 378.108,123.812 374.947,120.795 C371.971,117.589 368.438,115.043 364.346,113.157 C360.255,111.271 355.885,110.328 351.236,110.328 C346.401,110.328 341.937,111.271 337.846,113.157 C333.941,115.043 330.407,117.589 327.246,120.795 C324.27,123.812 321.946,127.395 320.272,131.545 C318.598,135.505 317.762,139.748 317.762,144.275 Z"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
          <path
            d="M579.385,190.103 C572.69,198.778 564.229,205.474 554.001,210.188 C543.772,214.903 533.172,217.261 522.2,217.261 C511.786,217.261 501.93,215.563 492.631,212.169 C483.519,208.774 475.522,203.965 468.642,197.741 C461.947,191.329 456.647,183.691 452.741,174.827 C448.836,165.963 446.883,156.062 446.883,145.123 C446.883,134.185 448.836,124.284 452.741,115.42 C456.647,106.556 461.947,99.012 468.642,92.788 C475.522,86.376 483.519,81.473 492.631,78.078 C501.93,74.683 511.786,72.986 522.2,72.986 C531.87,72.986 540.611,74.683 548.422,78.078 C556.418,81.473 563.113,86.376 568.506,92.788 C574.085,99.012 578.362,106.556 581.338,115.42 C584.313,124.284 585.801,134.185 585.801,145.123 L585.801,158.419 L488.726,158.419 C490.4,166.529 494.026,173.035 499.605,177.939 C505.184,182.654 512.065,185.011 520.248,185.011 C527.128,185.011 532.893,183.502 537.543,180.485 C542.378,177.279 546.562,173.224 550.095,168.32 L579.385,190.103 Z M543.958,129.564 C544.144,122.398 541.82,116.268 536.985,111.176 C532.149,106.084 525.92,103.538 518.295,103.538 C513.646,103.538 509.554,104.293 506.021,105.801 C502.488,107.31 499.419,109.29 496.816,111.742 C494.398,114.005 492.445,116.74 490.958,119.946 C489.656,122.964 488.912,126.17 488.726,129.564 L543.958,129.564 Z"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
          <path
            d="M698,110.328 L661.179,110.328 L661.179,156.156 C661.179,159.928 661.365,163.417 661.737,166.623 C662.109,169.641 662.946,172.281 664.247,174.544 C665.549,176.807 667.502,178.599 670.105,179.919 C672.895,181.051 676.521,181.616 680.984,181.616 C683.216,181.616 686.099,181.428 689.632,181.051 C693.351,180.485 696.141,179.353 698,177.656 L698,213.017 C693.351,214.715 688.516,215.846 683.495,216.412 C678.474,216.978 673.546,217.261 668.711,217.261 C661.644,217.261 655.135,216.506 649.184,214.998 C643.233,213.489 638.026,211.131 633.563,207.925 C629.1,204.531 625.566,200.193 622.963,194.912 C620.545,189.632 619.336,183.219 619.336,175.676 L619.336,110.328 L592.557,110.328 L592.557,76.381 L619.336,76.381 L619.336,35.644 L661.179,35.644 L661.179,76.381 L698,76.381 L698,110.328 Z"
            id="Path"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
          <path
            d="M708.664,145.123 C708.664,134.185 710.616,124.284 714.522,115.42 C718.427,106.556 723.727,99.012 730.422,92.788 C737.303,86.376 745.299,81.473 754.412,78.078 C763.71,74.683 773.567,72.986 783.981,72.986 C794.395,72.986 804.158,74.683 813.271,78.078 C822.569,81.473 830.566,86.376 837.26,92.788 C844.141,99.012 849.534,106.556 853.44,115.42 C857.345,124.284 859.297,134.185 859.297,145.123 C859.297,156.062 857.345,165.963 853.44,174.827 C849.534,183.691 844.141,191.329 837.26,197.741 C830.566,203.965 822.569,208.774 813.271,212.169 C804.158,215.563 794.395,217.261 783.981,217.261 C773.567,217.261 763.71,215.563 754.412,212.169 C745.299,208.774 737.303,203.965 730.422,197.741 C723.727,191.329 718.427,183.691 714.522,174.827 C710.616,165.963 708.664,156.062 708.664,145.123 Z M750.507,145.123 C750.507,155.496 753.389,163.889 759.154,170.301 C765.105,176.713 773.381,179.919 783.981,179.919 C794.581,179.919 802.763,176.713 808.528,170.301 C814.479,163.889 817.455,155.496 817.455,145.123 C817.455,134.751 814.479,126.358 808.528,119.946 C802.763,113.534 794.581,110.328 783.981,110.328 C773.381,110.328 765.105,113.534 759.154,119.946 C753.389,126.358 750.507,134.751 750.507,145.123 Z"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
          <path
            d="M878.36,76.381 L918.53,76.381 L918.53,95.052 L919.09,95.052 C920.39,92.411 922.25,89.771 924.67,87.131 C927.09,84.49 929.97,82.133 933.32,80.058 C936.67,77.984 940.48,76.286 944.75,74.966 C949.03,73.646 953.68,72.986 958.7,72.986 C969.3,72.986 977.86,74.683 984.37,78.078 C990.87,81.284 995.9,85.81 999.43,91.657 C1003.15,97.503 1005.66,104.387 1006.96,112.308 C1008.26,120.229 1008.91,128.81 1008.91,138.051 L1008.91,213.866 L967.07,213.866 L967.07,146.538 C967.07,142.577 966.88,138.523 966.51,134.373 C966.33,130.036 965.49,126.075 964,122.492 C962.7,118.909 960.56,115.986 957.59,113.722 C954.8,111.459 950.71,110.328 945.31,110.328 C939.92,110.328 935.55,111.365 932.2,113.44 C928.85,115.325 926.25,117.966 924.39,121.36 C922.72,124.567 921.6,128.244 921.04,132.393 C920.49,136.542 920.21,140.88 920.21,145.406 L920.21,213.866 L878.36,213.866 L878.36,76.381 Z"
            id="Path"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
          <path
            d="M1036.87,0 L1078.71,0 L1078.71,91.091 L1079.27,91.091 C1084.11,84.49 1090.24,79.87 1097.68,77.229 C1105.31,74.4 1113.49,72.986 1122.23,72.986 C1131.9,72.986 1140.55,75.061 1148.17,79.21 C1155.8,83.17 1162.21,88.545 1167.42,95.334 C1172.81,102.124 1176.9,109.951 1179.69,118.814 C1182.67,127.49 1184.16,136.637 1184.16,146.255 C1184.16,156.628 1182.58,166.152 1179.41,174.827 C1176.25,183.502 1171.7,191.046 1165.75,197.458 C1159.98,203.682 1153.01,208.585 1144.82,212.169 C1136.64,215.563 1127.53,217.261 1117.49,217.261 C1112.65,217.261 1108.1,216.601 1103.82,215.28 C1099.54,213.96 1095.54,212.263 1091.82,210.188 C1088.29,208.114 1085.13,205.851 1082.34,203.399 C1079.74,200.759 1077.6,198.213 1075.92,195.761 L1075.36,195.761 L1075.36,213.866 L1036.87,213.866 L1036.87,0 Z M1075.36,145.123 C1075.36,155.496 1078.25,163.889 1084.01,170.301 C1089.96,176.713 1098.24,179.919 1108.84,179.919 C1119.44,179.919 1127.62,176.713 1133.39,170.301 C1139.34,163.889 1142.31,155.496 1142.31,145.123 C1142.31,134.751 1139.34,126.358 1133.39,119.946 C1127.62,113.534 1119.44,110.328 1108.84,110.328 C1098.24,110.328 1089.96,113.534 1084.01,119.946 C1078.25,126.358 1075.36,134.751 1075.36,145.123 Z"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
          <path
            d="M1198.07,145.123 C1198.07,134.185 1200.02,124.284 1203.93,115.42 C1207.83,106.556 1213.13,99.012 1219.83,92.788 C1226.71,86.376 1234.71,81.473 1243.82,78.078 C1253.12,74.683 1262.97,72.986 1273.39,72.986 C1283.8,72.986 1293.57,74.683 1302.68,78.078 C1311.98,81.473 1319.97,86.376 1326.67,92.788 C1333.55,99.012 1338.94,106.556 1342.85,115.42 C1346.75,124.284 1348.7,134.185 1348.7,145.123 C1348.7,156.062 1346.75,165.963 1342.85,174.827 C1338.94,183.691 1333.55,191.329 1326.67,197.741 C1319.97,203.965 1311.98,208.774 1302.68,212.169 C1293.57,215.563 1283.8,217.261 1273.39,217.261 C1262.97,217.261 1253.12,215.563 1243.82,212.169 C1234.71,208.774 1226.71,203.965 1219.83,197.741 C1213.13,191.329 1207.83,183.691 1203.93,174.827 C1200.02,165.963 1198.07,156.062 1198.07,145.123 Z M1239.91,145.123 C1239.91,155.496 1242.8,163.889 1248.56,170.301 C1254.51,176.713 1262.79,179.919 1273.39,179.919 C1283.99,179.919 1292.17,176.713 1297.94,170.301 C1303.89,163.889 1306.86,155.496 1306.86,145.123 C1306.86,134.751 1303.89,126.358 1297.94,119.946 C1292.17,113.534 1283.99,110.328 1273.39,110.328 C1262.79,110.328 1254.51,113.534 1248.56,119.946 C1242.8,126.358 1239.91,134.751 1239.91,145.123 Z"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
          <path
            d="M1452.85,196.61 L1452.29,196.61 C1447.64,203.965 1441.41,209.245 1433.6,212.452 C1425.98,215.658 1417.89,217.261 1409.33,217.261 C1403.01,217.261 1396.88,216.318 1390.92,214.432 C1385.16,212.734 1380.04,210.094 1375.58,206.511 C1371.12,202.928 1367.59,198.496 1364.98,193.215 C1362.38,187.934 1361.08,181.805 1361.08,174.827 C1361.08,166.906 1362.47,160.211 1365.26,154.742 C1368.24,149.272 1372.14,144.746 1376.98,141.163 C1382,137.58 1387.67,134.845 1393.99,132.959 C1400.32,130.884 1406.82,129.376 1413.52,128.433 C1420.4,127.49 1427.19,126.924 1433.88,126.735 C1440.76,126.547 1447.09,126.453 1452.85,126.453 C1452.85,118.909 1450.15,112.968 1444.76,108.63 C1439.55,104.104 1433.32,101.841 1426.07,101.841 C1419.19,101.841 1412.87,103.35 1407.1,106.367 C1401.52,109.196 1396.5,113.157 1392.04,118.249 L1369.72,95.052 C1377.53,87.696 1386.65,82.227 1397.06,78.644 C1407.48,74.872 1418.26,72.986 1429.42,72.986 C1441.69,72.986 1451.74,74.589 1459.55,77.795 C1467.54,80.813 1473.87,85.339 1478.51,91.374 C1483.35,97.409 1486.7,104.858 1488.56,113.722 C1490.42,122.398 1491.35,132.488 1491.35,143.992 L1491.35,213.866 L1452.85,213.866 L1452.85,196.61 Z M1442.53,153.61 C1439.37,153.61 1435.37,153.799 1430.54,154.176 C1425.89,154.365 1421.33,155.119 1416.87,156.439 C1412.59,157.759 1408.87,159.739 1405.71,162.38 C1402.73,165.02 1401.25,168.698 1401.25,173.413 C1401.25,178.505 1403.38,182.276 1407.66,184.728 C1411.94,187.18 1416.4,188.406 1421.05,188.406 C1425.14,188.406 1429.05,187.84 1432.77,186.708 C1436.67,185.577 1440.11,183.974 1443.09,181.899 C1446.06,179.825 1448.39,177.184 1450.06,173.978 C1451.92,170.772 1452.85,167 1452.85,162.663 L1452.85,153.61 L1442.53,153.61 Z"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
          <path
            d="M1512.02,76.381 L1553.86,76.381 L1553.86,98.446 L1554.42,98.446 C1558.88,89.96 1564.18,83.642 1570.32,79.493 C1576.46,75.155 1584.17,72.986 1593.47,72.986 C1595.89,72.986 1598.31,73.08 1600.73,73.269 C1603.14,73.458 1605.38,73.835 1607.42,74.4 L1607.42,113.157 C1604.45,112.214 1601.47,111.554 1598.49,111.176 C1595.7,110.611 1592.73,110.328 1589.57,110.328 C1581.57,110.328 1575.25,111.459 1570.6,113.722 C1565.95,115.986 1562.32,119.192 1559.72,123.341 C1557.3,127.301 1555.72,132.11 1554.98,137.768 C1554.23,143.426 1553.86,149.65 1553.86,156.439 L1553.86,213.866 L1512.02,213.866 L1512.02,76.381 Z"
            id="Path"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
          <path
            d="M1757.4,213.866 L1718.9,213.866 L1718.9,195.761 L1718.35,195.761 C1716.67,198.213 1714.44,200.759 1711.65,203.399 C1709.05,205.851 1705.89,208.114 1702.17,210.188 C1698.63,212.263 1694.63,213.96 1690.17,215.28 C1685.89,216.601 1681.43,217.261 1676.78,217.261 C1666.74,217.261 1657.63,215.563 1649.44,212.169 C1641.26,208.585 1634.19,203.682 1628.24,197.458 C1622.48,191.046 1618.02,183.502 1614.85,174.827 C1611.69,166.152 1610.11,156.628 1610.11,146.255 C1610.11,136.637 1611.51,127.49 1614.3,118.814 C1617.27,109.951 1621.36,102.124 1626.57,95.334 C1631.96,88.545 1638.47,83.17 1646.1,79.21 C1653.72,75.061 1662.37,72.986 1672.04,72.986 C1680.78,72.986 1688.87,74.4 1696.31,77.229 C1703.93,79.87 1710.16,84.49 1715,91.091 L1715.56,91.091 L1715.56,0 L1757.4,0 L1757.4,213.866 Z M1718.9,145.123 C1718.9,134.751 1715.93,126.358 1709.98,119.946 C1704.21,113.534 1696.03,110.328 1685.43,110.328 C1674.83,110.328 1666.55,113.534 1660.6,119.946 C1654.84,126.358 1651.95,134.751 1651.95,145.123 C1651.95,155.496 1654.84,163.889 1660.6,170.301 C1666.55,176.713 1674.83,179.919 1685.43,179.919 C1696.03,179.919 1704.21,176.713 1709.98,170.301 C1715.93,163.889 1718.9,155.496 1718.9,145.123 Z"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
        </svg>
      </a>
      <span className="gray size1 insights-title">Insights</span>
    </div>
  );
}
