import React from "react";

import sortBy from "lodash/fp/sortBy";
import { ResponsiveBar } from "@nivo/bar";
import { gobColorScheme, gobTheme } from "../utils";
import Loading from "../components/Loading";
import ReportHeader from "../components/ReportHeader";
import SalariesGap from "../components/SalariesGap";
import useInsights from "../hooks/useInsights";
import InsightsProBanner from "./InsightsProBanner";

const SuccessRates = () => {
  const data = useInsights("placement_rate_per_tags");

  const renderPlacementRates = (data) => {
    const barExtraHeight = data.length > 5 ? data.length * 25 : 0;
    return (
      <div style={{ height: 200 + barExtraHeight }}>
        <ResponsiveBar
          data={sortBy("placement_rate")(data)}
          layout="horizontal"
          groupMode="grouped"
          keys={["placement_rate"]}
          indexBy="tag"
          colors={gobColorScheme}
          margin={{ top: 50, right: 20, bottom: 60, left: 150 }}
          padding={0.3}
          labelFormat={(value) => `${value}%`}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          borderRadius={4}
          colorBy="index"
          axisTop={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: `% of jobs that successfully hired a candidate up to ${new Date().getFullYear()}`,
            legendPosition: "middle",
            legendOffset: 52,
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            legendPosition: "middle",
            legendOffset: 32,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={"#fff"}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          theme={gobTheme}
        />
      </div>
    );
  };

  return (
    <div>
      <ReportHeader
        epigraph="Salary gaps"
        headline="How are offered and expected salaries matching?"
        icon="fav-list"
      >
        <p className="size1 mb1">
          The chart below shows the relationship between: (a) average salaries
          offered by employers; (b) salary expectations of qualified candidates
          (i.e. excluding candidates discarded for not meeting qualifications)
          applying to those same jobs; and (c) salary expectations of those
          candidates that were actually hired.
        </p>
        <p>
          All salaries shown are referential, expressed monthly, in USD, and
          after taxes and insurance discounts.
        </p>
      </ReportHeader>
      <SalariesGap />
      <div className="mb3" />
      <ReportHeader
        epigraph="Success rates"
        headline="What positions were successful finding candidates?"
        icon="fav-list"
      >
        <p className="size1 mb1">
          This chart shows the percentage of jobs in a given technology that
          completed successfully a hire in Get on Board up to {new Date().getFullYear()}. A high
          success rate (above 60%) indicates that the market is offering
          attractive positions to candidates, <em>and</em> that there is enough
          amount of candidates to cover demand.
        </p>
        <p className="size1">
          A low success rate might indicate that there are too few qualified
          candidates for these positions, or that the positions being offered
          are not attractive enough to candidates.
        </p>
      </ReportHeader>
      {data ? renderPlacementRates(data) : <Loading />}

      <InsightsProBanner
        title="Tómale el pulso al mercado"
        copy="Insights Pro te entrega aún más detalles sobre cómo se comporta el mercado del talento tech en LATAM."
        cta="Conoce más de Insights Pro"
        tag="success-rates"
      />
    </div>
  );
};

export default SuccessRates;
