import React from "react";

import sortBy from "lodash/fp/sortBy";
import { ResponsiveBar } from "@nivo/bar";
import { gobColorScheme, gobTheme } from "../utils";
import Loading from "../components/Loading";
import ReportHeader from "../components/ReportHeader";
import useInsights from "../hooks/useInsights";
import InsightsProBanner from "./InsightsProBanner";

const OfferedPerks = () => {
  const data = useInsights("offered_perks");

  const renderOfferedPerks = (data) => {
    const barExtraHeight = data.length > 5 ? data.length * 25 : 0;
    return (
      <div style={{ height: 200 + barExtraHeight }}>
        <ResponsiveBar
          data={sortBy("count")(data)}
          layout="horizontal"
          groupMode="grouped"
          keys={["count"]}
          indexBy="perk"
          colors={gobColorScheme}
          margin={{ top: 50, right: 20, bottom: 60, left: 150 }}
          padding={0.3}
          labelFormat={(value) => `${value}`}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          borderRadius={4}
          colorBy="index"
          axisLeft={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisBottom={false}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={"#fff"}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          theme={gobTheme}
        />
      </div>
    );
  };

  return (
    <div>
      <ReportHeader
        epigraph="Popular perks"
        headline="What benefits are offering companies hiring in tech?"
        icon="fireworks"
      >
        <p className="size1 mb1">When recruiting tech professionals, perks can make a big difference. Perks (such as flexible work, insurance coverage and other benefits) evidence company culture and values and speak about its vision and mindset.</p>
        <p className="size1">This is the list of perks most frequently used in jobs in Get on Board in the last 12 months, updated daily.</p>
      </ReportHeader>
      {data ? renderOfferedPerks(data) : <Loading />}

      <InsightsProBanner
        title="¿Quieres saber si los beneficios que tu compañía ofrece hacen match con las tendencias del mercado?"
        copy="Entonces necesitas Insights Pro."
        cta="Conoce más de Insights Pro"
        tag="offered-perks"
      />

      
    </div>
  );
};

export default OfferedPerks;
