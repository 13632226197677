export default function InsightsProBanner({title, copy,cta,tag}) {
  return (
    <section className="mt2 br2x p1">
      <div className="row bg-ipro-blue p3 border-radius2x" id="ipro-banner">
        <div className="col-12 col-md-7  flex flex-v-centered">
          <div className="text-left">
            <img src="/insights-pro-logo.svg" width="200" alt="Insights Pro logo"/>
            <h4 className="size5 w900 mb1 lh6 my2">{title}</h4>
            <p className="size1 mb3">{copy}</p>
            <a href="https://getonbrd.com/insightspro"><button className="gb-btn big" id={`ipro-banner--${tag}`}>{cta}</button></a>
          </div>
        </div>
        <div className="col-12 col-md-5 ">
          <img src="/insights-pro-graphic.png" className="full-width" alt=""/>
        </div>
      </div>
    </section>
  );
}

