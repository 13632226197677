import React from "react";
import { useState } from "react";
import Menu from "./Menu";

const DropdownNav = () => {
  const [closed, setClosed] = useState(true);

  return (
    <div
      className={`show-on-mobile-and-tablet dropdown ${closed ? "" : "open"}`}
      onClick={() => setClosed(!closed)}
    >
      <button className="p-3 dropdown-toggle gb-btn gb-btn--ghost">
        <span className="size1 px-1">Reports</span>
        <span className="caret mr-3" />
      </button>
      <Menu
        className="dropdown-menu dropdown-menu-right bg-sherpa-blue"
        navClassName="size1"
        activeClassName="bold white bg-blue"
      />
    </div>
  );
};
export default DropdownNav;
