import React, { useEffect, useState } from "react";

import groupBy from "lodash/fp/groupBy";

import { gobColorScheme, gobTheme, formatMoney } from "../utils";
import Loading from "../components/Loading";
import { ResponsiveLine } from "@nivo/line";
import { find } from "lodash/fp";
import useInsights from "../hooks/useInsights";

const SalariesGap = () => {
  const data = useInsights("expected_vs_offered_salaries");
  const [tag, setTag] = useState();
  const [seniority, setSeniority] = useState("Senior");
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (data) setTags(data.tags);
  }, [data]);

  useEffect(() => {
    if (tags) setTag(tags[0]);
  }, [tags]);

  const formatThousands = (num) =>
    Math.abs(num) > 999
      ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)} k`
      : Math.sign(num) * Math.abs(num);

  const mapDataToNivoFormat = ({
    id,
    series,
    tag,
    seniority,
    yName = "salary_average",
  }) => {
    const tagData = series.data.filter((entry) => entry.tag === tag);
    const seniorityData = groupBy("seniority")(tagData)[seniority];
    if (!seniorityData) return;
    return {
      id: id || series.series,
      data: seniorityData.map((d) => ({
        x: d.semester,
        y: d[yName],
      })),
    };
  };

  const baseline = 800;

  const Chart = ({ data }) => (
    <div style={{ height: 500 }}>
      <ResponsiveLine
        data={data}
        margin={{ top: 30, right: 20, bottom: 200, left: 40 }}
        yScale={{
          type: "linear",
          stacked: false,
          min: baseline,
        }}
        curve="monotoneX"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        enableGridX={true}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendOffset: -50,
          legendPosition: "middle",
          format: (value) => formatThousands(value),
        }}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        colors={gobColorScheme}
        theme={gobTheme}
        enableArea
        areaBaselineValue={baseline}
        areaOpacity={0.1}
        legends={[
          {
            anchor: "bottom",
            direction: "column",
            justify: false,
            translateX: -80,
            translateY: 150,
            itemsSpacing: 5,
            itemDirection: "left-to-right",
            itemWidth: 130,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        enableSlices="x"
        sliceTooltip={({ slice }) => {
          return (
            <div className="bg-sherpa-blue border-radius p-1 box-shadow">
              {slice.points.map((point) => (
                <div
                  key={point.id}
                  style={{
                    color: point.serieColor,
                    padding: "3px 0",
                  }}
                >
                  <strong>{point.serieId}</strong>:
                  <span className="ml-2">{formatMoney(point.data.y)}</span>
                </div>
              ))}
            </div>
          );
        }}
      />
    </div>
  );

  const VisualizeTag = ({ data, tag, seniority }) => {
    const series = [];
    if (tag && seniority) {
      const offerdSalariesSeries = find({ series: "offered-salaries" })(data);
      const generalExpectedSalaries = find({
        series: "general-expected-salaries",
      })(data);
      const hiredExpectedSalaries = find({ series: "hired-expected-salaries" })(
        data
      );

      series.push(
        mapDataToNivoFormat({
          id: "Average salary offered by employers",
          series: offerdSalariesSeries,
          tag: tag,
          seniority: seniority,
        })
      );
      series.push(
        mapDataToNivoFormat({
          id: "Average salary expectation of all candidates",
          series: generalExpectedSalaries,
          tag,
          seniority,
        })
      );
      series.push(
        mapDataToNivoFormat({
          id: "Average salary expectations of hired candidates",
          series: hiredExpectedSalaries,
          tag,
          seniority,
        })
      );
    }
    if (series.length) {
      return <Chart data={series} />;
    } else {
      return (
        <p className="mt3">
          No data found for {tag} and {seniority}
        </p>
      );
    }
  };

  if (!data) return <Loading />;

  const LinkForSeniority = ({ label }) => (
    <button
      key={label}
      className={`gb-filters__pill ${
        seniority === label ? "gb-filters__pill--active" : ""
      }`}
      href="#"
      onClick={({ target: { value } }) => setSeniority(label)}
    >
      {label}
    </button>
  );

  return (
    <div>
      <div className="row justify-center">
        <div className="col-md-6 justify-center flex mbb">
          <div className="size0 bold flex flex-v-centered">Select a skill:</div>
          <div className="select-container mx1">
            <select
              className="size1"
              defaultValue={tag}
              onChange={({ target: { value } }) => setTag(value)}
            >
              {tags.map((t) => (
                <option className="sherpa-blue" key={t}>
                  {t}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="seniorityFilters col-md-6 flex">
          <div className="flex">
            <LinkForSeniority label="Senior" />
            <LinkForSeniority label="Semi Senior" />
            <LinkForSeniority label="Junior" />
          </div>
        </div>
      </div>
      {data && tag && seniority && (
        <VisualizeTag
          className="mt4"
          data={data.series}
          tag={tag}
          seniority={seniority}
        />
      )}
    </div>
  );
};

export default SalariesGap;
