import { NavLink } from "react-router-dom";
import InsightsProBanner from "../components/InsightsProBanner";

export default function Home() {
  return (
    <div className="text-center">
      <img src="/statistics.svg" width="100" className="mb2 mr2 hide-on-small-mobile" />
      <h1 className="size5 w900 mb2 lh6">
        Real-time job market trends in Latin America
      </h1>
      <p className="size2 col-sm-9 center">
        <span className="bold">Insights</span> is an open project by{" "}
        <a href="https://www.getonbrd.com" target="_blank">
          Get on Board
        </a>{" "}
        that uses our unique job data to report up-to-date trends of the tech talent market in Latin
        America.
        <div className="row m2 flex justify-center">
          <div className="lh4 col-md-3">
            <div className="w900 size4 m0">40,000+</div>jobs
          </div>
          <div className="lh4 col-md-3">
            <div className="w900 size4 m0">2,500,000+</div>applications
          </div>
          <div className="lh4 col-md-3">
            <div className="w900 size4 m0">1,000,000+</div>professionals
          </div>
        </div>
      </p>
      <h2 className="h2 w900 mt5 mb1">See the reports</h2>
      <div className="row">
        <NavLink className="block col-sm-6" to="/reports/tags-cloud">
          <div className="p2 box-shadow my-1 bg-sherpa-blue-2 op6 border-radius2x">
            <img src="/settings.svg" width="72" />
            <h3 className="h3 mt0 bold white">Hot skills</h3>
            <p className="size1">
              What are the skills most often required by employers?
            </p>
          </div>
        </NavLink>
        <NavLink
          className="block col-sm-6"
          to="/reports/success-rates-salaries"
        >
          <div className="p2 box-shadow my-1 bg-sherpa-blue-2 op6 border-radius2x">
            <img src="/fav-list.svg" width="72" />
            <h3 className="h3 mt0 bold white">Success rates and salaries</h3>
            <p className="size1">
              How are offered and expected salaries matching?
            </p>
          </div>
        </NavLink>
      </div>
      <div className="row">
        <NavLink className="block col-sm-6" to="/reports/supply-vs-demand">
          <div className="p2 box-shadow my-1 bg-sherpa-blue-2 op6 border-radius2x">
            <img src="/transactions.svg" width="72" />
            <h3 className="h3 mt0 bold white">Supply v/s demand</h3>
            <p className="size1">How jobs and applications are matching across technologies and remote modalities?</p>
          </div>
        </NavLink>
        <NavLink className="block col-sm-6" to="/reports/most-active-companies">
          <div className="p2 box-shadow my-1 bg-sherpa-blue-2 op6 border-radius2x">
            <img src="/metrics.svg" width="72" />
            <h3 className="h3 mt0 bold white">
              Active companies
            </h3>
            <p className="size1">
              What are the most active companies looking for?
            </p>
          </div>
        </NavLink>
        <NavLink
          className="block col-sm-6"
          to="/reports/responsive-companies"
        >
          <div className="p2 box-shadow my-1 bg-sherpa-blue-2 op6 border-radius2x">
            <img src="/r-chat.svg" width="72" />
            <h3 className="h3 mt0 bold white">Responsive companies</h3>
            <p className="size1">What are the fastest companies getting back to candidates?</p>
          </div>
        </NavLink>
        <NavLink
          className="block col-sm-6"
          to="/reports/popular-perks"
        >
          <div className="p2 box-shadow my-1 bg-sherpa-blue-2 op6 border-radius2x">
            <img src="/fireworks.svg" width="72" />
            <h3 className="h3 mt0 bold white">Popular perks</h3>
            <p className="size1">What are the perks that companies are offering most often?</p>
          </div>
        </NavLink>
      </div>

      <InsightsProBanner
        title="El futuro de la inteligencia salarial"
        copy="Insights Pro es un reporte trimestral que le da a tu empresa la información que necesita para seguirle el pulso al mercado."
        cta="Conoce más de Insights Pro"
        tag="home"
      />


      <section>
        <h2 className="h2 w900 mt5 mb1">About this project</h2>
        <p className="size1 mt1">
          Get on Board is the biggest recruitment platform in the tech industry
          in Latin-America. Our unique integration of a job board with our own
          Applicant Tracking System (ATS) gives as a unique view of the
          recruitment market, as we can observe trends not only in public jobs,
          but also on applications to those jobs and their results, over time.
        </p>
        <p className="size1 mt1">
          The data shown in the charts implements our API and has been filtered
          and curated to showcase the categories and technologies where we have
          a significant amount of data, both for privacy and for statistical
          significance reasons.
        </p>
        <p className="size1 mtb mb5">
        If you are a tech professional looking to better understand how to
        advance your career, a team leader or recruiter trying to improve your
        hiring strategy, an educator or a policymaker in technology looking to
        make better decisions, the following charts and reports will be useful
        to you.
      </p>
      </section>
    </div>
  );
}
