import { NavLink } from "react-router-dom";

export default function Menu({ className, navClassName, activeClassName }) {
  return (
    <ul className={className}>
      <li>
        <NavLink
          className={navClassName}
          to="/reports/tags-cloud"
          activeClassName={activeClassName}
        >
          Hot skills
        </NavLink>
      </li>
      <li>
        <NavLink
          className={navClassName}
          to="/reports/success-rates-salaries"
          activeClassName={activeClassName}
        >
          Salaries and success rates
        </NavLink>
      </li>
      <li>
        <NavLink
          className={navClassName}
          to="/reports/supply-vs-demand"
          activeClassName={activeClassName}
        >
          Supply v/s demand
        </NavLink>
      </li>
      <li>
        <NavLink
          className={navClassName}
          to="/reports/most-active-companies"
          activeClassName={activeClassName}
        >
          Active companies
        </NavLink>
      </li>
      <li>
        <NavLink
          className={navClassName}
          to="/reports/responsive-companies"
          activeClassName={activeClassName}
        >
          Responsive companies
        </NavLink>
      </li>
      <li>
        <NavLink
          className={navClassName}
          to="/reports/popular-perks"
          activeClassName={activeClassName}
        >
          Popular perks
        </NavLink>
      </li>
    </ul>
  );
}
