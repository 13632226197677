export default function Loading() {
  return (
    <div className="loading">
      <div className="text-center mb2">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
        Loading charts...
      </div>
    </div>
  );
}
