import React from "react";

import { ResponsiveBubble } from "@nivo/circle-packing";
import Loading from "../components/Loading";
import ReportHeader from "../components/ReportHeader";
import { blueMonoColorScheme, gobTheme } from "../utils";
import useInsights from "../hooks/useInsights";
import InsightsProBanner from "./InsightsProBanner";

const TagsCloud = () => {
  const data = useInsights("tags_cloud_per_categories");

  const CustomBubbleNode = ({ node, style, handlers, theme }: bubbleNodeType) => {
    if (style.r <= 0) return null;
  
    return (
      <g transform={`translate(${style.x},${style.y})`}>
        <circle
          r={style.r}
          {...handlers}
          fill={style.fill ? style.fill : style.color}
          stroke={style.borderColor}
          strokeWidth={style.borderWidth}
        />
        {node.label !== false && (
          <text
            textAnchor="middle"
            dominantBaseline="central"
            style={{
              ...theme.labels.text,
              fontSize: `${Math.round(
                (style.r / 5) * (11 / node.label.toString().substring(0, style.r / 4).length),
              )}px`,
              fill: style.labelTextColor,
              pointerEvents: 'none',
            }}
          >
            {node.label}
          </text>
        )}
      </g>
    );
  };

  const renderCloud = (cat) => {
    const nivoData = {
      name: cat.category,
      children: cat.data.map((d) => ({
        name: d.tag,
        relevance: d.relevance,
      })),
    };
    return (
      <div className="mt3" key={cat.category}>
        <h3 className="h3 text-center w700">{cat.category}</h3>
        <div className="responsiveBubble">
          <ResponsiveBubble
            root={nivoData}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            identity="name"
            value="relevance"
            colors={blueMonoColorScheme}
            padding={2}
            labelTextColor="#fff"
            borderWidth={1}
            borderColor="rgba(255,255,255,0.3)"
            animate={true}
            motionStiffness={90}
            motionDamping={12}
            labelSkipRadius={18}
            colorBy="name"
            nodeComponent={CustomBubbleNode}
            theme={gobTheme}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <ReportHeader
        epigraph="Hot skills, by category"
        headline="What are the skills most often required by employers?"
        icon="settings"
      >
        <p className="size1 mb1">
          The following charts show how often certain skills and technologies
          appear in job ads, broken down by category, in the last 12 months. As a
          professional, you can get a sense of the kind of knowledge that is
          most requested by potential employers. If you want to assess what
          skills to learn, this is a good place to start.
        </p>
      </ReportHeader>
      {data ? data.map((cat) => renderCloud(cat)) : <Loading />}

      <InsightsProBanner
        title="Tómale el pulso al mercado"
        copy="Insights Pro te entrega aún más detalles sobre cómo se comporta el mercado del talento tech en LATAM."
        cta="Conoce más de Insights Pro"
        tag="tags-cloud"
      />


    </div>
  );
};

export default TagsCloud;
